import { Spinner } from 'reactstrap';

export const LoaderSmall = () => (
    <div className="preloader">
      <div className="status">
        <Spinner color="primary" />
      </div>
    </div>
  );

const Loader = () => (
    <div className="app-c-loader">
      <div className="spinner-chase">
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
        <div className="chase-dot" />
      </div>
    </div>
  );

export default Loader;
