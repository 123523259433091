import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import { get } from '../Libraries/Ajax/httpService';
import { DefaultImageProfileTop } from '../Pages/User/defaultImage';

// users
class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      apiResponse: {},
      singleRow: null,
    };
    this.row = {};
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    if (this.props.userData.user.id) {
      get(`user/${this.props.userData.user.id}`).then((response) => {
        this.row = response.data.data;
        this.setState({ apiResponse: response.data.data });
      });
    }
  }

  render() {
    let { apiResponse, isLoaderActive } = this.state;
    let { userData } = this.props;
    return (
      <React.Fragment>
        <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
            {apiResponse?.userImageUrl ? (
              <img className="rounded-circle header-profile-user pull-right" alt="User " src={apiResponse.userImageUrl} />
            ) : (
              <div className="rounded-circle header-profile-user pull-right bg-dark">
                <DefaultImageProfileTop rowData={apiResponse} firstName="firstName" />
              </div>
            )}
          </DropdownToggle>
          <DropdownMenu right>
            <Link to={`/my-profile/${userData.user.id}`} className="dropdown-item">
              <i className="mdi mdi-account-circle font-size-20 align-middle mr-1" />
              <span>My Profile</span>
            </Link>
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="mdi mdi-logout font-size-17 align-middle mr-1" />
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  let { userData } = state.Login;
  return { userData };
};

export default connect(mapStateToProps)(ProfileMenu);
