import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onMenuChange = () => {
    let matchingMenuItem = null;
    let ul = document.getElementById('navigation');
    let items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove('active');
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        // break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  };
  componentDidMount() {
    this.onMenuChange();
    // let matchingMenuItem = null;
    // let ul = document.getElementById('navigation');
    // let items = ul.getElementsByTagName('a');
    // for (let i = 0; i < items.length; ++i) {
    //   if (this.props.location.pathname === items[i].pathname) {
    //     matchingMenuItem = items[i];
    //     break;
    //   }
    // }
    // if (matchingMenuItem) {
    //   this.activateParentDropdown(matchingMenuItem);
    // }
  }

  componentDidUpdate() {
    this.onMenuChange();
  }
  activateParentDropdown = (item) => {
    item.classList.add('active');
    // let parent = item.parentElement;

    // if (parent) {

    //   parent.classList.add('active'); // li
    //   let parent2 = parent.parentElement;

    //   parent2.classList.add('active'); // li
    //   let parent3 = parent2.parentElement;
    //   if (parent3) {
    //     parent3.classList.add('active'); // li
    //     let parent4 = parent3.parentElement;
    //     if (parent4) {
    //       parent4.classList.add('active'); // li
    //       let parent5 = parent4.parentElement;
    //       if (parent5) {
    //         parent5.classList.add('active'); // li
    //       }
    //     }
    //   }
    // }

    return false;
  };

  render() {
    let { userData } = this.props;
    return (
      <div>
        {process.env.REACT_APP_THEME === 'SubTo' && (
          <React.Fragment>
            <div className="topnav">
              <div className="container-fluid">
                <div className="blue-nav d-lg-none d-md-none"></div>
                <nav className="navbar navbar-light navbar-expand-lg navbar-expand-md navbar-expand-xl topnav-menu">
                  <Collapse isOpen={this.props.menuOpen} className="navbar-collapse menu-center collapsed-menu">
                    <div id="navigation">
                      <div className="collapsed-menu">
                        <ul className="navbar-nav">
                          <li>
                            <Link className="nav-link highlight navlinks " to="/dashboard">
                              <i className="ri-dashboard-line"></i>
                              Dashboard
                            </Link>
                          </li>
                          <li>
                            <Link className="nav-link highlight navlinks " to="/list-leads">
                              <i className="ri-group-line"></i>
                              <span>Leads</span>
                            </Link>
                          </li>

                          {userData.user.roleId === 2 && (
                            <li>
                              <Link className="nav-link highlight navlinks " to="/list-leads/senior?">
                                <i className="ri-user-2-line"></i>
                                <span>My Leads</span>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </Collapse>
                </nav>
              </div>
              <div className="new-line"></div>
            </div>
          </React.Fragment>
        )}
        {process.env.REACT_APP_THEME === 'Astro' && (
          <React.Fragment>
            <div className="topnav">
              <div className="container-fluid">
                <div className="blue-nav d-lg-none d-md-none"></div>
                <nav className="navbar navbar-light navbar-expand-lg navbar-expand-md navbar-expand-xl topnav-menu">
                  <Collapse isOpen={this.props.menuOpen} className="navbar-collapse menu-center">
                    <div id="navigation">
                      <div className="collapsed-menu">
                        <ul className="navbar-nav">
                          <li>
                            <Link className="nav-link highlight navlinks" to="/dashboard">
                              <i className="ri-dashboard-line"></i>
                              <span>Dashboard</span>
                            </Link>
                          </li>
                          <li>
                            <Link className="nav-link highlight navlinks" to="/list-leads">
                              <i className="ri-group-line"></i>
                              <span>Leads</span>
                            </Link>
                          </li>
                          {userData.user.roleId === 2 && (
                            <li>
                              <Link className="nav-link highlight navlinks" to="/list-leads/senior?">
                                <i className="ri-user-2-line"></i>
                                <span>My Leads</span>
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </Collapse>
                </nav>
              </div>
              <div className="new-line"></div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});
export default withRouter(connect(mapStateToProps, null)(Navbar));
