import { all } from 'redux-saga/effects';

// public
import LayoutSaga from './Layout/saga';

export default function* rootSaga() {
  yield all([
    // public
    // AccountSaga(),
    // AuthSaga(),
    // ForgetSaga(),
    LayoutSaga(),
  ]);
}
