import React from 'react';
import ReactTooltip from 'react-tooltip';

const SetToolTipText = (props) => {
  let { dataTip, dataPlace, dataId } = props;

  return (
    <>
      <span data-tip={dataTip} data-place={dataPlace || 'top'} data-for={dataId} data-arrow-color="transparent">
        {props.children}
      </span>
      <ReactTooltip className="tool-tip" effect="solid" id={dataId} />
    </>
  );
};

export default SetToolTipText;
