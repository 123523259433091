import React from 'react';
import License from '../../SharedComponents/footerLicense';

const Footer = () => (
  <React.Fragment>
    <footer className="footer">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <License />
        </div>
      </div>
    </footer>
  </React.Fragment>
);

export default Footer;
