import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, Dropdown, DropdownToggle } from 'reactstrap';

import logodarkImg from '../../assets/images/ASTRO_WHITE.png';
import logodmImg from '../../assets/images/filename.jpg';
import logolightImg from '../../assets/images/subtoinvert.PNG';
import ProfileMenu from '../profileMenu';

// import images
// Import other Dropdown
class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: false,
    };

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  componentDidMount() {
    const url = `${process.env.REACT_APP_SECOND_API_URL}checkuserexist`;
    axios.get(url, { params: { email: this.props.userData.user.email } }).then((response) => {
      this.setState({ result: response.data.data.checkUserExist });
    });
  }

  switchScreen() {
    const dependentUrl = [
      {
        url: 'my-profile',
        redirect: '/dashboard',
      },
      {
        url: 'dashboard',
        redirect: '/dashboard',
      },
      {
        url: 'list-leads',
        redirect: '/list-leads',
      },
      {
        url: 'list-leads/senior',
        redirect: '/list-leads/senior',
      },
      {
        url: 'change-password',
        redirect: '/dashboard',
      },
      {
        url: 'view-lead',
        redirect: '/list-leads',
      },
      {
        url: 'manage-lead',
        redirect: '/list-leads',
      },
      {
        url: 'edit-profile',
        redirect: '/dashboard',
      },
    ];
    for (let item of dependentUrl) {
      if (window.location.href.includes(item.url)) {
        window.location.href = `https://${process.env.REACT_APP_HOST}${item.redirect}`;
        console.log(window.location.href);
      }
    }
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // this.props.toggleMenuCallback();
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }

  render() {
    let { result } = this.state;
    return (
      <div>
        {process.env.REACT_APP_THEME == 'SubTo' && (
          <React.Fragment>
            <header id="page-topbar">
              <div className="navbar-header navbar-height">
                <div className="d-flex">
                  <div className="navbar-brand-box navbar-horizontal" style={{ backgroundColor: '#0C2340' }}>
                    <Link to="/" className="logo logo-light">
                      <span className="logo-lg">
                        <img src={logolightImg} alt="" height="40" />
                      </span>
                      <span className="logo-sm">
                        <img src={logolightImg} alt="" height="40" />
                      </span>
                    </Link>
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm mr-2 font-size-24 d-lg-none d-md-none header-item menu-open waves-effect waves-light"
                    onClick={this.toggleMenu}
                  >
                    <i className="mdi mdi-menu"></i>
                  </button>
                </div>
                <div className="d-flex">
                  {result == true && (
                    <div className="d-none d-sm-block horizontal-button">
                      <Dropdown
                        isOpen={this.state.createMenu}
                        toggle={() => this.setState({ createMenu: !this.state.createMenu })}
                        className="user-button pr-3 d-inline-block"
                      >
                        <DropdownToggle className="btn btn-highlight" tag="button">
                          Subto <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="language-switch mr-3" right>
                          <DropdownItem tag="a" className="notify-item item-drop" onClick={this.switchScreen}>
                            <span className="align-middle">Astroflipping</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    // <div className="user-button pr-3">
                    //   <button type="button" onClick={this.switchScreen} class="btn btn-secondary btn-sm ">
                    //     Astroflipping
                    //   </button>
                    // </div>
                  )}
                  <div className="dropdown d-none d-lg-inline-block"></div>
                  <div className="new-header">
                    <ProfileMenu />
                  </div>
                </div>
              </div>
            </header>
          </React.Fragment>
        )}
        {process.env.REACT_APP_THEME == 'Astro' && (
          <React.Fragment>
            <header id="page-topbar">
              <div className="navbar-header navbar-height">
                <div className="d-flex">
                  <div className="navbar-brand-box navbar-horizontal">
                    <Link to="/" className="logo logo-light">
                      <span className="logo-lg">
                        <img src={logodmImg} className="mr-2" alt="" height="45" />
                        <img src={logodarkImg} alt="" height="40" />
                      </span>
                      <span className="logo-sm">
                        <img src={logodmImg} className="mr-2" alt="" height="45" />
                        <img src={logodarkImg} alt="" height="40" />
                      </span>
                    </Link>
                  </div>
                  <button
                    type="button"
                    className="btn btn-sm mr-2 font-size-24 d-lg-none d-md-none header-item menu-open waves-effect waves-light"
                    onClick={this.toggleMenu}
                  >
                    <i className="mdi mdi-menu"></i>
                  </button>
                </div>
                <div className="d-flex">
                  {result == true && (
                    <div className="d-none d-sm-block horizontal-button">
                      <Dropdown
                        isOpen={this.state.createMenu}
                        toggle={() => this.setState({ createMenu: !this.state.createMenu })}
                        className="user-button pr-3 d-inline-block"
                      >
                        <DropdownToggle className="btn btn-secondary" tag="button">
                          Astroflipping <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="language-switch mr-3" right>
                          <DropdownItem tag="a" className="notify-item item-drop" onClick={this.switchScreen}>
                            <span className="align-middle">Subto</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                  <div className="dropdown d-none d-lg-inline-block"></div>
                  <div className="new-header">
                    <ProfileMenu />
                  </div>
                </div>
              </div>
            </header>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});

export default connect(mapStateToProps)(TopBar);
