import React from 'react';
import SetToolTipText from './toolTipText';

const License = () => (
  <>
    {process.env.REACT_APP_THEME == 'SubTo' && (
      <div className="mt-0 text-center">
        <SetToolTipText
          dataTip="Subto nor any of its affiliates are liable for any misrepresentations regarding property,
            property values, ownership, offer, price, or anything else related to individual posts related to real estate properties or transactions.
            Each individual agrees to enter into real estate transaction(s) and assume the risks.Subto nor its affiliates review the posts for accuracy
                and/or any claims by other users related to the application and/or real estate property and transactions."
          dataId="license"
        >
          <p className="mb-0 new-year">© {new Date().getFullYear()} Subto.</p>
        </SetToolTipText>
      </div>
    )}
    {process.env.REACT_APP_THEME == 'Astro' && (
      <div className="mt-0 text-center">
        <SetToolTipText
          dataTip="AstroFlipping nor any of its affiliates are liable for any misrepresentations regarding property,
            property values, ownership, offer, price, or anything else related to individual posts related to real estate properties or transactions.
            Each individual agrees to enter into real estate transaction(s) and assume the risks. AstroFlipping nor its affiliates review the posts for accuracy
                and/or any claims by other users related to the application and/or real estate property and transactions."
          dataId="license"
        >
          <p className="mb-0 new-year">© {new Date().getFullYear()} Astro.</p>
        </SetToolTipText>
      </div>
    )}
  </>
);

export default License;
