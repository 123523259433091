import dotenv from 'dotenv';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import HorizontalLayout from './Components/HorizontalLayout';
import Loader from './Components/loader';
import NonAuthLayout from './Components/nonAuthLayout';
import VerticalLayout from './Components/VerticalLayout';
import history from './Helpers/history';
import { authProtectedRoutes, publicRoutes } from './Routes';
import AppRoute from './Routes/route';
import { changeLayout } from './Store/actions';

// Import Routes
// layouts
const SubtoTheme = React.lazy(() => import('./subto'));
const AstroTheme = React.lazy(() => import('./astro'));
dotenv.config();
const ThemeSelector = ({ children }) => (
  <>
    <React.Suspense fallback={<Loader />}>
      {process.env.REACT_APP_THEME == 'SubTo' && <SubtoTheme />}
      {process.env.REACT_APP_THEME == 'Astro' && <AstroTheme />}
    </React.Suspense>
    {children}
  </>
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;
    switch (this.props.layout.layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  // componentDidMount() {
  //   let prevPath = sessionStorage.getItem('currentPath');
  //   sessionStorage.setItem('prevPath', prevPath);
  //   // Set the current path value by looking at the browser's location object.
  //   sessionStorage.setItem('currentPath', history.location.pathname);
  //   console.log(history);
  // }

  componentDidUpdate() {
    let { userData, updateLayout, layout } = this.props;
    if (userData) {
      let userRole = userData.user.roleId;
      if (layout.layoutType != 'vertical' && userRole != 1 && userRole != 2) {
        updateLayout('vertical');
      }
      if (layout.layoutType != 'horizontal' && (userRole == 1 || userRole == 2)) {
        updateLayout('horizontal');
      }
    }
    // let storage = globalThis?.sessionStorage;
    // if (!storage) return;
    // Set the previous path as the value of the current path.
    // let prevPath = sessionStorage.getItem('currentPath');
    // sessionStorage.setItem('prevPath', prevPath);
    // // Set the current path value by looking at the browser's location object.
    // console.log('b4', history);

    // sessionStorage.setItem('currentPath', history.location.pathname);
    // console.log(history);
  }

  render() {
    let Layout = this.getLayout();
    return (
      <ThemeSelector>
        <React.Fragment>
          <Router history={history}>
            <React.Suspense fallback={<Loader />}>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <AppRoute path={route.path} layout={NonAuthLayout} component={route.component} key={idx} isAuthProtected={false} />
                ))}

                {authProtectedRoutes.map((route, idx) => (
                  <AppRoute path={route.path} layout={Layout} component={route.component} key={idx} isAuthProtected />
                ))}
              </Switch>
            </React.Suspense>
          </Router>
        </React.Fragment>
      </ThemeSelector>
    );
  }
}

const mapStateToProps = (state) => ({
  layout: state.Layout,
  userData: state.Login.userData,
});

const mapDispatchToProps = (dispatch) => ({
  updateLayout: (theme) => dispatch(changeLayout(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
