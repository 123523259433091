import { SET_USER, LOGOUT_USER, API_ERROR, SET_LOADING_INACTIVE, SET_LOADING_ACTIVE, LOGIN_MESSAGE } from './actionTypes';

export const initialState = {
  error: null,
  loading: false,
  userData: null,
  loginMessageState: null,
  messageSuccess: null,
};

const loginData = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER:
      state = {
        ...state,
        userData: payload,
        loading: false,
        loginMessageState: null,
        messageSuccess: null,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, userData: null };
      break;
    case API_ERROR:
      state = { ...state, error: payload, loading: false };
      break;
    case LOGIN_MESSAGE:
      state = {
        ...state,
        loginMessageState: payload.message,
        messageSuccess: payload.success,
        loading: false,
      };
      break;
    case SET_LOADING_INACTIVE:
      state = { ...state, loading: false };
      break;
    case SET_LOADING_ACTIVE:
      state = { ...state, loading: true };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default loginData;
