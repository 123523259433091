import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';
import { initialState } from './Auth/Login/reducer';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
function loadFromLocalStorage() {
  try {
    let serialisedState = localStorage.getItem('userData');

    if (serialisedState === null) return undefined;
    return {
      Login: { ...initialState, userData: JSON.parse(serialisedState) },
    };
  } catch (e) {
    return undefined;
  }
}

const store = createStore(rootReducer, loadFromLocalStorage(), composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

export default store;
