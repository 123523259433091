import React from 'react';
import { Redirect } from 'react-router-dom';

// import FormAdvanced from '../Pages/Forms/FormAdvanced';
// import FormEditor from '../Pages/Forms/FormEditor';
// import FormElement from '../Pages/Forms/FormElement';
// import FormMask from '../Pages/Forms/FormMask';
// import FormRepeater from '../Pages/Forms/FormRepeater';
// import FormUpload from '../Pages/Forms/FormUpload';
// import FormValidation from '../Pages/Forms/FormValidation';
// import FormWizard from '../Pages/Forms/FormWizard';
// import FormXeditable from '../Pages/Forms/FormXeditable';

const ForgetPwd = React.lazy(() => import('../Pages/Authentication/forgetPassword'));
const Login = React.lazy(() => import('../Pages/Authentication/login'));
const Logout = React.lazy(() => import('../Pages/Authentication/logout'));
const Register = React.lazy(() => import('../Pages/Authentication/register'));
const RequestAlerts = React.lazy(() => import('../Pages/Authentication/RequestAlert'));
const Signup = React.lazy(() => import('../Pages/Authentication/signup'));
const Pages404 = React.lazy(() => import('../Pages/ExtraPages/Pages404'));
const Pages500 = React.lazy(() => import('../Pages/ExtraPages/Pages500'));
const PagesMaintenance = React.lazy(() => import('../Pages/ExtraPages/PagesMaintenance'));
const SetPassword = React.lazy(() => import('../Pages/ExtraPages/SetPassword'));
const AdminListLead = React.lazy(() => import('../Pages/Leads/adminListLeads'));
const ListLead = React.lazy(() => import('../Pages/Leads/listLead'));
const ManageLead = React.lazy(() => import('../Pages/Leads/manageLead'));
const ViewLead = React.lazy(() => import('../Pages/Leads/viewLead'));
const MapsGoogle = React.lazy(() => import('../Pages/Maps/MapsGoogle'));
const MapsVector = React.lazy(() => import('../Pages/Maps/MapsVector'));
const EditProfile = React.lazy(() => import('../Pages/ProfileMenu/editProfile'));
const ChangePassword = React.lazy(() => import('../Pages/ProfileMenu/changePassword'));
const MyProfile = React.lazy(() => import('../Pages/ProfileMenu/myProfile'));
const ListPendingUserContainer = React.lazy(() => import('../Pages/User/listPendingUser'));
const ListUserContainer = React.lazy(() => import('../Pages/User/listUser'));
const ManageUsers = React.lazy(() => import('../Pages/User/manageUser'));
const ViewUsers = React.lazy(() => import('../Pages/User/viewUser'));
const Dashboard = React.lazy(() => import('../Pages/Dashboard'));

// Pages Component

// Authentication related pages
// UI Pages
// Form
// Charts
// Tables
// Maps
// Maps
// Extra Pages
// Extra Pages
// User
//  Lead
// view lead
// Profile Menu
// Profile Permission
// Shop
// Product
// Obituary
// Orders
// Library Management
// Activity on Memeorial
// Listing Leads
// import ListLeadContainer from '../Pages/Leads/listLead';

const authProtectedRoutes = [
  // User
  { path: '/view-users/:id', component: ViewUsers },
  { path: '/list-users', component: ListUserContainer },
  { path: '/pending-users', component: ListPendingUserContainer },
  { path: '/manage-users/:id?', component: ManageUsers },

  // Lead
  { path: '/list-leads', component: ListLead },
  { path: '/list-leads/senior?', component: ListLead },
  { path: '/manage-leads/:id?', component: ManageLead },
  { path: '/admin-leads', component: AdminListLead },
  { path: '/view-lead/:id?', component: ViewLead },

  // Profile Menu
  { path: '/my-profile/:id', component: MyProfile },
  { path: '/edit-profile/:id?', component: EditProfile },
  { path: '/change-password/:id?', component: ChangePassword },

  // Form
  // { path: '/form-elements', component: FormElement },
  // { path: '/form-validation', component: FormValidation },
  // { path: '/form-advanced', component: FormAdvanced },
  // { path: '/form-mask', component: FormMask },
  // { path: '/form-repeater', component: FormRepeater },
  // { path: '/form-uploads', component: FormUpload },
  // { path: '/form-xeditable', component: FormXeditable },
  // { path: '/form-wizard', component: FormWizard },
  // { path: '/form-editors', component: FormEditor },

  // Maps
  { path: '/maps-google', component: MapsGoogle },
  { path: '/maps-vector', component: MapsVector },
  // Activity on Memorial

  // Extra Pages

  { path: '/dashboard', component: Dashboard },

  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: '/logout', component: Logout },
  { path: '/signup', component: Signup },
  { path: '/login', component: Login },
  { path: '/forgot-Password', component: ForgetPwd },
  { path: '/pages-Register', component: Register },
  { path: '/request-alert', component: RequestAlerts },

  // Extra Pages
  { path: '/lock-screen', component: SetPassword },
  { path: '/set-password', component: SetPassword },
  { path: '/recovery-password', component: SetPassword },

  { path: '/pages-404', component: Pages404 },
  { path: '/pages-500', component: Pages500 },
  { path: '/pages-Maintenance', component: PagesMaintenance },
];

export { authProtectedRoutes, publicRoutes };
