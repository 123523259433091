import MetisMenu from 'metismenujs';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import logosmImg from '../../assets/images/subtohand.PNG';
import logolightImg from '../../assets/images/subtofull.PNG';
import logodarkImg from '../../assets/images/ASTRO_WHITE.png';
import logodmImg from '../../assets/images/filename.jpg';

const SidebarContent = (props) => (
  <div id="sidebar-menu">
    {process.env.REACT_APP_THEME == 'SubTo' && (
      <div className="dashboard-new">
        <Link to="/dashboard" className="logo logo-light">
          <span className="logo-sm">
            <img src={logosmImg} alt="" height="55" />
          </span>
          <span className="logo-lg">
            <img src={logolightImg} alt="" height="55" />
          </span>
        </Link>
      </div>
    )}

    {process.env.REACT_APP_THEME == 'Astro' && (
      <div className="dashboard-new1">
        <Link to="/dashboard" className="logo logo-light">
          <span className="logo-sm">
            <img src={logodmImg} alt="" height="55" />
          </span>
          <span className="logo-lg">
            <img src={logodmImg} className="mr-2" alt="" height="60" />
            <img src={logodarkImg} alt="" height="55" />
          </span>
        </Link>
      </div>
    )}
    <ul className="metismenu list-unstyled left-menu" id="side-menu">
      <li>
        <Link to="/dashboard" className="waves-effect">
          <i className="ri-dashboard-line"></i>
          <span>Dashboard</span>
        </Link>
      </li>
      <li>
        <Link to="/admin-leads">
          <i className="ri-group-line"></i>
          <span>Leads</span>
        </Link>
      </li>
      <li>
        <Link to="/list-users">
          <i className="ri-user-settings-line"></i>
          <span>Manage Users</span>
        </Link>
      </li>
      <li>
        <Link to="/pending-users">
          <i className="ri-user-add-line"></i>
          <span>Pending Users</span>
        </Link>
      </li>
    </ul>
  </div>
);
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    let currentPathName = this.props.location.pathname;
    let previousPathName = prevProps.location.pathname;
    if (
      this.props.type !== prevProps.type ||
      (currentPathName.includes('/list-template/') && previousPathName.includes('/list-template/') && currentPathName !== previousPathName)
    ) {
      this.initMenu();
    }
  }

  initMenu() {
    if (this.props.type !== 'condensed' || this.props.isMobile) {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      let ul = document.getElementById('side-menu');
      let items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove('mm-active');
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
    if (this.props.type === 'condensed') {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      let ul = document.getElementById('side-menu');
      let items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        items[i].classList.remove('mm-active');
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
        }
      }
      if (matchingMenuItem) {
        this.activateNewParentDropdown(matchingMenuItem);
      }
    }
  }
  activateNewParentDropdown = (item) => {
    item.classList.add('mm-active');
    let parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      let parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        let parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          let parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  activateParentDropdown = (item) => {
    item.classList.add('mm-active');
    let parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      let parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        let parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          let parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== 'condensed' ? (
          <SimpleBar style={{ maxHeight: '100%' }}>
            <SidebarContent />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
