import { SET_USER, LOGOUT_USER, API_ERROR, SET_LOADING_INACTIVE, SET_LOADING_ACTIVE, LOGIN_MESSAGE } from './actionTypes';

export const setUser = (user) => ({
  type: SET_USER,
  payload: user,
});

export const logoutUser = () => ({
  type: LOGOUT_USER,
  payload: {},
});

export const apiError = (error) => ({
  type: API_ERROR,
  payload: error,
});
export const loginMessage = (success, message) => ({
  type: LOGIN_MESSAGE,
  payload: { success, message },
});
export const setLoadingActive = () => ({
  type: SET_LOADING_ACTIVE,
  payload: null,
});
export const setLoadingInactive = () => ({
  type: SET_LOADING_INACTIVE,
  payload: null,
});
