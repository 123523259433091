import React from 'react';

let getImage = (firstName, secondName) => {
  let value;
  if (firstName && secondName) {
    value = (firstName?.split(' ').shift().charAt(0) + secondName?.split(' ').shift().charAt(0)).toUpperCase();
  } else if (firstName?.split(' ').length > 1) {
    value = (firstName?.split(' ').shift().charAt(0) + firstName?.split(' ').pop().charAt(0)).toUpperCase();
  } else {
    value = firstName?.charAt(0).toUpperCase();
  }
  return value;
};

export const DefaultImage = ({ rowData, firstName, secondName }) => (
  <div className="dr-profile-wrapper">
    {rowData && <span className="image-from-initials">{getImage(rowData[firstName], rowData[secondName])}</span>}
  </div>
);
export const DefaultImageDashboard = ({ rowData, firstName, secondName }) => (
  <div className="dr-profile-wrapper">
    {rowData && <span className="image-from-initials-dashboard">{getImage(rowData[firstName], rowData[secondName])}</span>}
  </div>
);

export const DefaultImage1 = ({ rowData, firstName, secondName }) => (
  <div className="dr-profile-wrapper">
    {rowData && <span className="image-from-initials1">{getImage(rowData[firstName], rowData[secondName])}</span>}
  </div>
);

export const DefaultImageProfileTop = ({ rowData, firstName, secondName }) => (
  <div className="dr-profile-wrapper">
    {rowData && <span className="image-from-initials-profile-top">{getImage(rowData[firstName], rowData[secondName])}</span>}
  </div>
);

export const DefaultImageProfile = ({ rowData, firstName, secondName }) => (
  <div className="dr-profile-wrapper">
    {rowData && <span className="image-from-initials-profile">{getImage(rowData[firstName], rowData[secondName])}</span>}
  </div>
);
export default DefaultImage;
