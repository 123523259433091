import axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, Dropdown, DropdownToggle } from 'reactstrap';
import ProfileMenu from '../profileMenu';

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchOpen: false,
      result: false,
    };

    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  componentDidMount() {
    const url = `${process.env.REACT_APP_SECOND_API_URL}checkuserexist`;
    axios.get(url, { params: { email: this.props.userData.user.email } }).then((response) => {
      this.setState({ result: response.data.data.checkUserExist });
    });
  }

  switchScreen() {
    const dependentUrl = [
      {
        url: 'my-profile',
        redirect: '/dashboard',
      },
      {
        url: 'dashboard',
        redirect: '/dashboard',
      },
      {
        url: 'admin-leads',
        redirect: '/admin-leads',
      },
      {
        url: 'view-users',
        redirect: '/list-users',
      },
      {
        url: 'change-password',
        redirect: '/dashboard',
      },
      {
        url: 'manage-users',
        redirect: '/list-users',
      },
      {
        url: 'manage-leads',
        redirect: '/admin-leads',
      },
      {
        url: 'edit-profile',
        redirect: '/dashboard',
      },
      {
        url: 'pending-users',
        redirect: '/pending-users',
      },
      {
        url: 'list-users',
        redirect: '/list-users',
      },
      {
        url: 'view-lead',
        redirect: '/admin-leads',
      },
    ];
    for (let item of dependentUrl) {
      if (window.location.href.includes(item.url)) {
        window.location.href = `https://${process.env.REACT_APP_HOST}${item.redirect}`;
        console.log(window.location.href);
      }
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this.setState({ isSearchOpen: !this.state.isSearchOpen });
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }

  render() {
    let { result } = this.state;
    return (
      <div>
        {process.env.REACT_APP_THEME == 'SubTo' && (
          <React.Fragment>
            <header id="page-topbar" id="page-new">
              {/* <div className="vertical-topbar"> */}
              <div className="navbar-header">
                <div className="d-flex">
                  {/* <div className="navbar-brand-box">
                    <Link to="/dashboard" className="logo logo-light">
                      <span className="logo-sm">
                        <img src={logosmImg} alt="" height="55" />
                      </span>
                      <span className="logo-lg">
                        <img src={logolightImg} alt="" height="60" />
                      </span>
                    </Link>
                  </div> */}
                  <button
                    type="button"
                    onClick={this.toggleMenu}
                    className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                    id="vertical-menu-btn"
                  >
                    <i className="mdi mdi-menu" />
                  </button>
                </div>

                <div className="d-flex">
                  {result == true && (
                    <div className="d-none d-sm-block">
                      <Dropdown
                        isOpen={this.state.createMenu}
                        toggle={() => this.setState({ createMenu: !this.state.createMenu })}
                        className="user-button pr-3 d-inline-block"
                      >
                        <DropdownToggle className="btn btn-secondary" tag="button">
                          Subto <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="language-switch mr-3" right>
                          <DropdownItem tag="a" className="notify-item item-drop" onClick={this.switchScreen}>
                            <span className="align-middle">Astroflipping</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    // <div className="user-button pr-3">
                    //   <button type="button" onClick={this.switchScreen} class="btn btn-secondary btn-sm ">
                    //     Astroflipping
                    //   </button>
                    // </div>
                  )}

                  <div className="dropdown d-none d-lg-inline-block"></div>
                  <div className="new-header1">
                    <ProfileMenu />
                  </div>
                </div>
              </div>
              {/* </div> */}
            </header>
          </React.Fragment>
        )}
        {process.env.REACT_APP_THEME == 'Astro' && (
          <React.Fragment>
            <header id="page-topbar" id="page-new">
              <div className="navbar-header">
                <div className="d-flex">
                  {/* <div className="navbar-brand-box">
                    <Link to="/dashboard" className="logo logo-light">
                      <span className="logo-sm">
                        <img src={logodmImg} alt="" height="55" />
                      </span>
                      <span className="logo-lg">
                        <img src={logodarkImg} alt="" height="60" />
                      </span>
                    </Link>
                  </div> */}
                  <button
                    type="button"
                    onClick={this.toggleMenu}
                    className="btn btn-sm px-3 font-size-24 header-item waves-effect"
                    id="vertical-menu-btn"
                  >
                    {this.state.isMenuOpen ? (
                      <>
                        <i className="mdi mdi-close" />
                      </>
                    ) : (
                      <>
                        <i className="mdi mdi-menu" />
                      </>
                    )}
                  </button>
                </div>

                <div className="d-flex">
                  {result == true && (
                    <div className="d-none d-sm-block">
                      <Dropdown
                        isOpen={this.state.createMenu}
                        toggle={() => this.setState({ createMenu: !this.state.createMenu })}
                        className="user-button pr-3 d-inline-block"
                      >
                        <DropdownToggle className="btn btn-secondary" tag="button">
                          Astroflipping <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="language-switch mr-3" right>
                          <DropdownItem tag="a" className="notify-item item-drop" onClick={this.switchScreen}>
                            <span className="align-middle">Subto</span>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                  <div className="dropdown d-none d-lg-inline-block"></div>
                  <div className="new-header1">
                    <ProfileMenu />
                  </div>
                </div>
              </div>
            </header>
          </React.Fragment>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  userData: state.Login.userData,
});

export default connect(mapStateToProps)(TopBar);
